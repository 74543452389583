<template>
  <div>
    <!-- 票据基本信息 -- 用的是工作台公共的子组件-里面包括票面预览和票面下载 -->
    <el-card>
      <WorkbenchPublicTitle :superInfoList="superInfoList">
        <template v-slot:titleText>
          <h3>票据基本信息</h3>
        </template>
      </WorkbenchPublicTitle>
      <el-table :data="superInfoList" border class="mt20">
        <el-table-column prop="packageNo" label="票据包号"></el-table-column>
        <el-table-column prop="packageSum" label="票据包金额"></el-table-column>
        <el-table-column prop="acceptor" label="承兑人"></el-table-column>
        <el-table-column prop="expireDate" label="票据到期日"></el-table-column>
        <el-table-column prop="ticketRange" label="子票区间"></el-table-column>
      </el-table>
    </el-card>
    <!-- 收款信息 -->
    <el-card>
      <el-form :model="paymentApplyForm" 
             :rules="paymentApplyRules" 
             ref="paymentApplyRef" 
             label-width="120px" 
             class="payment-apply-form mt20">
        <el-form-item label="收款人：" prop="compName">
          <span>{{paymentApplyForm.compName}}</span>
        </el-form-item>
        <el-form-item label="收款账号：" prop="acctNo">
          <el-select v-model="paymentApplyForm.acctNo" 
                     placeholder="请选择"
                     @change="handleAccountChange">
            <el-option v-for="(item, index) in accountList" 
                       :label="item" 
                       :value="item"
                       :key="index"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="开户行：" prop="acctBankName">
          <span>{{paymentApplyForm.acctBankName}}</span>
        </el-form-item>
        <el-form-item label="开户行行号：" prop="acctBankNo">
          <span>{{paymentApplyForm.acctBankNo}}</span>
        </el-form-item>
        <el-form-item label="清算方式：" prop="clrWay">
          <span>{{paymentApplyForm.clrWay}}</span>
        </el-form-item>
      </el-form>
    </el-card>
    <!-- 按钮 -->
    <div class="btn">
			<el-button @click="handleBack">返回</el-button>
			<el-button type="primary" @click="handleSubmit">确定提交</el-button>
		</div>
    <!--短信验证-->
    <el-dialog
            title="短信验证"
            :visible.sync="messageDialogVisible"
            :close-on-click-modal="false"
            width="40%"
            @close="messageDialogClosed">
      <el-form :model="messageForm"
               :rules="messageFormRules"
               ref="messageFormRef"
               class="message-form">
        <el-form-item label="操作员手机号">
          {{mobile}}
        </el-form-item>
        <el-form-item label="短信验证码" prop="verifyCode">
          <el-input v-model="messageForm.verifyCode" class="verify-code"></el-input>
          <DelayButton />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
				<el-button type="primary" @click="handleThroughSubmit">提交</el-button>
		    <el-button @click="messageDialogVisible = false">关闭</el-button>
		  </span>
    </el-dialog>
  </div>
</template>

<script>
  // 票面预览/票面下载
  import WorkbenchPublicTitle from "@/views/Ticket/routerCpn/workbench/publicCpn/WorkbenchPublicTitle";
  /*倒计时按钮*/
  import DelayButton from "@/views/Ticket/childrenCpn/DelayButton";
  export default {
    name: 'PaymentApplyOne',
    components: {
      WorkbenchPublicTitle,
      DelayButton
    },
    mounted(){
      // 列表返回的票据id
      this.ticketId = this.$route.query.ticketId
      // 获取提示付款申请信息
      this.getInfo()
    },
    data(){
      return {
        ticketId: '', // 列表返回的票据id
        superInfoList: [], // 公共组件 - 票面预览/下载
        paymentApplyForm: {
          compName: '', // 收款人
          acctNo:'', // 收款账号
          acctBankName: '', // 开户行
          acctBankNo:'', // 开户行行号
          clrWay: '' // 清算方式
        },
        paymentApplyRules: {
          compName: [
            { required: true, message: '收款人不能为空', trigger: 'blur' },
          ],
          acctNo: [
             { required: true, message: '请选择收款账号', trigger: 'change' }
          ],
          acctBankName: [
            { required: true, message: '开户行不能为空', trigger: 'blur' },
          ],
          acctBankNo: [
            { required: true, message: '开户行行号不能为空', trigger: 'blur' },
          ],
          clrWay: [
            { required: true, message: '清算方式不能为空', trigger: 'blur' },
          ]
        },
        accountList: [], // 收款账号
        mobile: this.$store.state.getUser, // 获取vuex中存的手机号
        identifyCode:'', // 短信标识码	
        messageDialogVisible: false, // 控制短信弹框的显示与隐藏
        // 短信验证表单数据
        messageForm: {
          verifyCode: ''
        },
        // 短信验证表单验证规则
        messageFormRules:{
          verifyCode: [
            { required: true, message: '请输入验证码', trigger: 'blur' }
          ]
        },
      }
    },
    methods: {
      // 获取提示付款申请信息
      async getInfo(){
        const {data: res} = await this.$auth.get(`/cpiaoju-ticket/fk/mange/apply/info/${this.ticketId}`);
        if(res.code !== 200) return this.$message.error(res.msg)
        this.superInfoList = res.data.baseInfos
        this.paymentApplyForm = {...res.data.payeeInfo}
        // 获取收款账号
        this.getAccount()
      },
      // 获取收款账号
      async getAccount(){
        const {data: res} = await this.$auth.get(`/cpiaoju-ticket/common/accounts/${this.paymentApplyForm.compName}`)
        if(res.code !== 200) return this.$message.error(res.msg)
        this.accountList = res.data.accountList
      },
      // 监听收款账号的改变
      async handleAccountChange(value){
        const {data: res} = await this.$auth.get(`/cpiaoju-ticket/common/account/info/${value}`)
        if(res.code !== 200) return this.$message.error(res.msg);
        this.paymentApplyForm.acctBankName = res.data.acctBankName
        this.paymentApplyForm.acctBankNo = res.data.acctBankNo
      },
      // 返回
      handleBack(){
        this.$router.push({name: 'paymentApplyList'})
      },
      // 确定提交
      handleSubmit(){
        this.$refs.paymentApplyRef.validate(valid => {
          if(!valid) return;
          this.messageDialogVisible = true
        })
      },
      // 短信确定提交
      handleThroughSubmit(){
        this.$refs.messageFormRef.validate(async valid => {
          if(!valid) return;
          // 提示付款申请提交参数
          let submitParams = {
            ticketId: this.ticketId, // 列表返回的票据id
            identifyCode: this.identifyCode, // 短信标识码	
            ...this.paymentApplyForm
          }
          // 短信验证
          const {data: res} = await this.$auth.post('/cpiaoju-ticket/common/verifyMsg',this.$qs.stringify({msgCode: this.messageForm.verifyCode}))
          if(res.code === 200){
            // 短信验证获取的短信标识码	
            this.identifyCode = res.data.identifyCode
            // 提示付款申请-确认提交
            const {data: result} = await this.$auth.post('/cpiaoju-ticket/fk/mange/apply/submit', this.$qs.stringify(submitParams))
            if(result.code !== 200) return this.$message.error(result.msg)
            this.messageDialogVisible = false
            this.$emit('handleSubmit', {activeStep: 2, text: result.data.text, handlerNo: result.data.handlerNo})
          }else{
            this.$message.error(res.msg)
            this.messageDialogVisible = true
          }
        })
      },
      // 审核通过短信验证关闭
      messageDialogClosed(){
        this.$refs.messageFormRef.resetFields()
      },
    }
  }
</script>

<style lang="less" scoped>
  .btn{
    text-align: center;
    margin: 50px auto 20px;
    .el-button{
      min-width: 120px;
      margin: 0 30px;
    }
  }
  .verify-code{
		width: 220px;
	}
</style>
  
